import styled from "styled-components"

export const Button = styled.button`
  width: auto;
  font-weight: 700;
  font-size: 1.875rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  border-radius: 0.25rem;
  color: #fffffe;
  font-size: 1.25rem;
  background-color: var(--main-color);
  box-shadow: var(--elevation1);
  border-top-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  transition: all 150ms cubic-bezier(1, 0, 0, 1);
  background: linear-gradient(185deg, #63baf8, #4d2ee0);
  background: var(--gradient2);
  width: 100%;
  font-size: 1.35rem;
  cursor: pointer;

  &:hover {
    transition: all 150ms cubic-bezier(1, 0, 0, 1);
    transform: scale(1.02);
    box-shadow: 0 4px 12px #383362;
  }
`
