import React, { useState, useLayoutEffect } from "react"

import MobileMockup from "../MobileMockup"

import * as S from "./styled.js"

const logoRedesign = {
  path: "logo-biotec-80-min.jpg",
  isMobile: false,
  typeOfWork: "Logo Redesign",
  alt: "Logo Biotec Inova Redesign",
  altOriginal: "Logo Biotec Inova Original",
  hasOriginal: true,
  pathOriginal: "logo-biotec-original-80-min.jpg",
}

const frontendCode1 = {
  path: "code-pjf-min.jpg",
  isMobile: false,
  typeOfWork: "Frontend Code",
  alt: "Print Portal de Serviços da Prefeitura de Juiz de Fora",
}

const frontendCode2 = {
  path: "groundpnp.jpg",
  isMobile: false,
  typeOfWork: "Frontend Code",
  alt: "Print site inspirado no AirBnB criado durante programa de treinamento Hiring Coders",
}

function randomNumber(min, max){
  const random = Math.random() * (max - min) + min
  return Math.ceil(random)
}

const Display = () => {
  const [displayItem, setDisplayItem] = useState(logoRedesign)

  useLayoutEffect(() => {
    const displayItems = [frontendCode1, logoRedesign, frontendCode2]

    setInterval(() => {
      const randomIndex = randomNumber(0, 2)
      setDisplayItem(displayItems[randomIndex])
    }, 5000)
  }, [])

  return (
    <S.Display>
      <S.DisplayCircles />
      <S.DisplayBars />
      <S.DisplayHeader>
        <S.DisplayHeaderDots />
        <S.DisplayHeaderDots />
        <S.DisplayHeaderDots />
      </S.DisplayHeader>

      <S.DisplayImg
        className="displayImg"
        src={`/assets/img/display/${displayItem.path}`}
        alt={displayItem.alt}
      />

      <S.DisplaySubtitle>{displayItem.typeOfWork}</S.DisplaySubtitle>

      {displayItem.hasOriginal && (
        <S.DisplayOriginal
          src={`/assets/img/display/${displayItem.pathOriginal}`}
          alt={displayItem.altOriginal}
        />
      )}
      
      {displayItem.isMobile && <MobileMockup />}
    </S.Display>
  )
}

export default Display
