import styled from "styled-components"
import media from "styled-media-query"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const Container = styled.article`
  width: 100%;
  margin: auto;
  text-align: left;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 8rem;

  div {
    width: 45%;
    padding-right: 5%;
  }

  ${media.lessThan("large")`
    width: 90%;
  `}

  ${media.lessThan("medium")`
    width: 100%;
    text-align: center;
    flex-flow: column nowrap;
    align-items: center;

    div {
      width: 90%;
      padding-right: 0;
    }
  `}
`
export const Title = styled.h1`
  margin-top: 3rem;
  letter-spacing: 0.025em;
  font-size: 4.5rem;
  color: var(--main-color);
  line-height: 1;
  font-weight: 600;
  font-family: var(--fontSerif);

  ${media.lessThan("large")`
    font-size: 3rem;
  `}
`
export const Subtitle = styled.h2`
  margin: 2.3rem 0;
  color: var(--main-color);
  font-size: 1.45rem;
  font-family: var(--fontSansSerif);
`

export const CTA = styled(AniLink)``

export const DisplayContainer = styled.section`
  margin-top: 4rem;
  width: 50%;

  ${media.lessThan("medium")`
    width: 90%;
    margin-top: 8rem;
  `}
`