import React from "react"

import Display from "./Display"

import SEO from "components/Seo"
import Button from "components/Button"
import Layout from "components/Layout"

import * as S from "./styled"

const Home = () => (
  <Layout>
    <SEO title="Home" />
    <S.Container>
      <div>
        <S.Title className="xl:text-7xl text-5xl md:text-4xl font-semibold text-purple-500 antialiased font-serif leading-none tracking-wide">
          Trazendo suas ideias à vida
        </S.Title>
        <S.Subtitle className="text-2xl md:text-xl text-purple-500 antialiased font-sans my-6">
          Entre em contato e dê o primeiro passo no seu novo projeto
        </S.Subtitle>

        <a href="mailto:emanuelpnandrade@gmail.com">
          <Button>Entrar em contato</Button>
        </a>
      </div>

      <S.DisplayContainer>
        <Display />
      </S.DisplayContainer>
    </S.Container>
  </Layout>
)
export default Home
