import styled from "styled-components"
import media from "styled-media-query"

export const Display = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  margin: auto;
  top: 0;
  width: 80%;
  height: 345px;
  background: #fff;

  ${media.lessThan("medium")`
    width: 100%;
  `}

  ${media.lessThan("small")`
    margin-bottom: 5.5rem;
  `}
`

export const DisplayCircles = styled.span`
  position: absolute;
  bottom: -40px;
  left: -75px;
  z-index: -1;
  width: 200px;
  height: 200px;
  border-radius: 200px;
  background: var(--red);
`
export const DisplayBars = styled.span`
  position: absolute;
  top: 20px;
  right: -90px;
  z-index: -1;
  width: 230px;
  height: 15px;
  border-radius: 200px;
  background: var(--yellow);
  transform: rotate(45deg);

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: -32px;
    z-index: -1;
    width: 230px;
    height: 20px;
    border-radius: 200px;
    background: var(--yellow);
  }

  &::after {
    top: 28px;
    height: 10px;
    border-radius: 200px;
  }
`
export const DisplayHeader = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  height: 35px;
  top: -34px;
  left: 0;
  right: 0;
  background: #e5e5e5;
  background: #69ddff;
  background: var(--gradient1);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`
export const DisplayHeaderDots = styled.span`
  background: #e5e5e5;
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 20px;
  margin-left: 20px;
`
export const DisplayImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`
export const DisplaySubtitle = styled.h2`
  position: absolute;
  font-size: 3.3rem;
  right: -4rem;
  bottom: -1.5rem;
  z-index: 5;
  color: #fff;
  font-family: var(--fontSerif);
  text-shadow: 0 1px 3px #302a50ab;

  &::after {
    content: "";
    position: absolute;
    left: -3%;
    right: -3%;
    bottom: 0;
    top: 8%;
    z-index: -1;
    background: rgba(73, 88, 231, 1);
    background: var(--gradient2);
    box-shadow: 0 4px 6px #38336247;
  }

  ${media.lessThan("medium")`
    right: 0;
  `}
  
  ${media.lessThan("small")`
    bottom: -5rem;
  `}
`

export const DisplayOriginal = styled.img`
  position: absolute;
  bottom: -1rem;
  left: -1rem;
  width: 100px;
  height: 100px;
  z-index: 2;
  box-shadow: 0 0 0 1px #e1dbff;
`
