import React from "react"

const MobileMockup = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1306 2599">
    <defs>
      <linearGradient id="grad1" x1="0%" y1="0%" x2="-90%" y2="100%">
        <stop offset="0%" stop-color="#63BAF8" stop-opacity="1" />
        <stop offset="100%" stop-color="#4D2EE0" stop-opacity="1" />
      </linearGradient>
    </defs>
    <path
      d="M.5 541.49a3.991 3.991 0 0 1 3.993-3.99H14.5v190H4.493A3.994 3.994 0 0 1 .5 723.51zm0 238a3.991 3.991 0 0 1 3.993-3.99H14.5v190H4.493A3.994 3.994 0 0 1 .5 961.51zm0-423.99a4 4 0 0 1 3.993-4H14.5v104H4.493a3.991 3.991 0 0 1-3.993-4zm1291 242h9a4.991 4.991 0 0 1 5 4.995v300.01a4.993 4.993 0 0 1-5 4.995h-9z"
      fill="#e1e1e1"
      stroke="#d2d2d2"
      stroke-miterlimit="10"
    />
    <path
      d="M1095.49 2598.5H210.51a201.486 201.486 0 0 1-40.309-4.063 198.914 198.914 0 0 1-71.518-30.095 200.588 200.588 0 0 1-72.465-87.995 199 199 0 0 1-11.654-37.541A201.457 201.457 0 0 1 10.5 2398.5v-2198a201.458 201.458 0 0 1 4.063-40.307 198.9 198.9 0 0 1 30.095-71.515 200.592 200.592 0 0 1 88-72.461A199.006 199.006 0 0 1 170.2 4.563 201.485 201.485 0 0 1 210.51.5h884.98a201.482 201.482 0 0 1 40.31 4.063 198.912 198.912 0 0 1 71.518 30.095 200.591 200.591 0 0 1 72.465 87.994 199 199 0 0 1 11.654 37.541 201.448 201.448 0 0 1 4.063 40.307v2198a201.459 201.459 0 0 1-4.063 40.307 198.893 198.893 0 0 1-30.095 71.515 200.6 200.6 0 0 1-88 72.461 199.041 199.041 0 0 1-37.544 11.654 201.489 201.489 0 0 1-40.308 4.063zM200.5 81.5a110.845 110.845 0 0 0-22.169 2.235A109.4 109.4 0 0 0 139 100.286a110.32 110.32 0 0 0-39.852 48.4 109.435 109.435 0 0 0-6.409 20.648A110.843 110.843 0 0 0 90.5 191.5v2216a110.85 110.85 0 0 0 2.235 22.17 109.41 109.41 0 0 0 16.551 39.33 110.31 110.31 0 0 0 48.4 39.853 109.44 109.44 0 0 0 20.648 6.409 110.834 110.834 0 0 0 22.166 2.238h905a110.844 110.844 0 0 0 22.169-2.235 109.4 109.4 0 0 0 39.333-16.551 110.315 110.315 0 0 0 39.852-48.4 109.47 109.47 0 0 0 6.409-20.649 110.838 110.838 0 0 0 2.237-22.165v-2216a110.848 110.848 0 0 0-2.235-22.17 109.4 109.4 0 0 0-16.551-39.334 110.31 110.31 0 0 0-48.4-39.852 109.409 109.409 0 0 0-20.648-6.409A110.843 110.843 0 0 0 1105.5 81.5h-134v18a71.586 71.586 0 0 1-12.316 40.256 72.307 72.307 0 0 1-31.724 26.086 71.738 71.738 0 0 1-28.06 5.658H406.6a71.833 71.833 0 0 1-40.314-12.294 72.209 72.209 0 0 1-26.123-31.676A71.468 71.468 0 0 1 334.5 99.5v-5.992a12 12 0 0 0-12-12.008c-.34 0-.676.014-1 .041V81.5z"
      fill="url(#grad1)"
      stroke="#d2d2d2"
      stroke-miterlimit="10"
    />
    <path
      d="M1093.5 2576.5h-881a181.32 181.32 0 0 1-36.275-3.657 179.012 179.012 0 0 1-64.362-27.084 180.521 180.521 0 0 1-65.214-79.2 179.119 179.119 0 0 1-10.488-33.789 181.353 181.353 0 0 1-3.661-36.276V202.505a181.335 181.335 0 0 1 3.657-36.277 179.032 179.032 0 0 1 27.084-64.365 180.528 180.528 0 0 1 79.193-65.217 179.067 179.067 0 0 1 33.787-10.489A181.307 181.307 0 0 1 212.5 22.5h881a181.316 181.316 0 0 1 36.275 3.657 179.013 179.013 0 0 1 64.362 27.084 180.523 180.523 0 0 1 65.213 79.2 179.1 179.1 0 0 1 10.488 33.789 181.347 181.347 0 0 1 3.662 36.275v2193.989a181.336 181.336 0 0 1-3.657 36.278 179.02 179.02 0 0 1-27.084 64.366 180.518 180.518 0 0 1-79.193 65.216 179.061 179.061 0 0 1-33.787 10.488 181.275 181.275 0 0 1-36.279 3.658zm-893-2495a110.124 110.124 0 0 0-110 110v2216a110.124 110.124 0 0 0 110 110h905a110.124 110.124 0 0 0 110-110v-2216a110.124 110.124 0 0 0-110-110h-134v18a71.6 71.6 0 0 1-12.315 40.256 72.306 72.306 0 0 1-31.724 26.086A71.739 71.739 0 0 1 899.4 171.5H406.6a71.843 71.843 0 0 1-40.314-12.294 72.2 72.2 0 0 1-26.123-31.677A71.47 71.47 0 0 1 334.5 99.5v-5.993a12 12 0 0 0-12-12.007c-.332 0-.668.014-1 .041V81.5z"
      fill="url(#grad1)"
      stroke="rgba(0,0,0,0)"
    />
    <path d="M1273.5 250.5h22v20h-22zm-1263 0h22v20h-22z" fill="#d6d6d6" />
    <g transform="translate(508.5 88.5)">
      <circle
        cx="12"
        cy="12"
        r="12"
        transform="translate(0 8)"
        fill="#f0f0f0"
      />
      <circle cx="8" cy="8" r="8" transform="translate(4 12)" fill="#d7d7d7" />
      <circle
        cx="20"
        cy="20"
        r="20"
        transform="translate(270)"
        fill="#f0f0f0"
      />
      <circle
        cx="16"
        cy="16"
        r="16"
        transform="translate(274 4)"
        fill="#d7d7d7"
      />
      <path d="M78 12h134a8 8 0 0 1 0 16H78a8 8 0 0 1 0-16" fill="#f0f0f0" />
      <path d="M78 16h134a4 4 0 0 1 0 8H78a4 4 0 0 1 0-8z" fill="#d7d7d7" />
    </g>
    <path
      d="M13 .041V0H0v12.008A12 12 0 0 1 12 0q.505 0 1 .041z"
      transform="translate(971.5 81.5)"
      fill="url(#a)"
    />
  </svg>
)

export default MobileMockup
